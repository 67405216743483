<template>
  <form
    class="mm-supplier-sign-up-personal-form"
    method="post"
  >
    <TextField
      v-model.trim="emailField.value.value"
      :validation-field="emailField"
      label="Электронная почта"
      icon-disabled
      is-only-submit-validate
      autocomplete="email"
      class="mm-supplier-sign-up-personal-form__field"
      name="email"
      type="email"
      @enter="onSubmit"
    />

    <TextField
      v-model.trim="phoneNumberField.value.value"
      :validation-field="phoneNumberField"
      mask="{+7} 000 000 00 00"
      label="Телефон"
      autocomplete="tel-national"
      type="tel"
      icon-disabled
      is-only-submit-validate
      class="mm-supplier-sign-up-personal-form__field"
      name="tel"
      @enter="onSubmit"
    />

    <input
      type="text"
      name="fakeusernameremembered"
      autocomplete="tel"
      tabindex="-1"
      class="mm-supplier-sign-up-personal-form__field--hidden"
    >

    <PasswordField
      v-model="passwordField.value.value"
      :validation-field="passwordField"
      name="new-password"
      autocomplete="new-password"
      placeholder="Пароль"
      class="mm-supplier-sign-up-personal-form__field"
      is-only-submit-validate
      @enter="onSubmit"
    />

    <PasswordField
      v-model="confirmPasswordField.value.value"
      :validation-field="confirmPasswordField"
      placeholder="Подтвердить пароль"
      class="mm-supplier-sign-up-personal-form__field"
      autocomplete="new-password"
      is-only-submit-validate
      name="new-password"
      @enter="onSubmit"
    />

    <div class="mm-supplier-sign-up-personal-form__agreement">
      <Checkbox
        v-model="isMailingField.value.value"
        :error-message="isMailingField.errorMessage.value"
      >
        Согласен на получение информационных материалов
      </Checkbox>
    </div>

    <div class="mm-supplier-sign-up-personal-form__footer">
      <button
        class="mm-supplier-sign-up-personal-form__button btn btn-primary"
        :disabled="loadingSignUp.value"
        type="button"
        @click="onSubmit"
      >
        Зарегистрироваться
      </button>
      <SupplierSignUpDocumentsFooter action-text="Зарегистрироваться" />
      <div class="mm-supplier-sign-up-personal-form__account">
        Уже есть аккаунт?
        <span
          class="mm-link"
          @click="$emit('login')"
        > Войти </span>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import SupplierSignUpDocumentsFooter from './SupplierSignUpDocumentsFooter.vue';
import TextField from 'shared/components/TextField.vue';
import PasswordField from 'shared/components/PasswordField.vue';
import Checkbox from 'shared/components/Checkbox.vue';
import { AccountApiService } from 'shared/services/api/accountApi.service';
import { Validators } from 'shared/utils/validators.util';
import { EValidatorsMessages } from 'shared/enums/validators.enum';
import { useField, useForm, useIsFormValid } from 'vee-validate';
import Loader from 'shared/utils/loaderHelper.util';
import { SupplierSignUpModalService } from 'services/supplier/supplierSignUpModal.service';

defineEmits<{
  (e: 'login'): void;
}>();

const supplierSignUpModalService = inject<SupplierSignUpModalService>(SupplierSignUpModalService.getServiceName());

enum ELoginFormFields {
  Email = 'email',
  Phone = 'phone',
  IsMailing = 'isMailing',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

const validationSchema = reactive({
  [ELoginFormFields.Email]: [Validators.required(EValidatorsMessages.Required), Validators.yupEmail(EValidatorsMessages.Email)],
  [ELoginFormFields.Phone]: [Validators.required(EValidatorsMessages.Required), Validators.phoneNumber(EValidatorsMessages.PhoneNumber)],
  [ELoginFormFields.Password]: [Validators.required(EValidatorsMessages.Required), Validators.password(EValidatorsMessages.Password)],
  [ELoginFormFields.ConfirmPassword]: [],
});

const form = useForm({
  validationSchema,
});

const emailField = useField<string>(ELoginFormFields.Email, undefined, { initialValue: '' });
const phoneNumberField = useField<string>(ELoginFormFields.Phone, undefined, { initialValue: '' });
const passwordField = useField<string>(ELoginFormFields.Password, undefined, { initialValue: '' });
const confirmPasswordField = useField<string>(ELoginFormFields.ConfirmPassword, undefined, { initialValue: '' });
const isMailingField = useField<boolean>(ELoginFormFields.IsMailing, undefined, { initialValue: false });

const isFormValid = useIsFormValid();

const loadingSignUp = Loader.getReactiveInstance();

async function onSubmit(): Promise<void> {
  supplierSignUpModalService?.clearResendEmail();

  loadingSignUp.activate();

  await form.validate();
  await validateEmail();

  setFormTouched();

  if (isFormValid.value) {
    await supplierSignUpModalService?.createUser({
      email: emailField.value.value.toLocaleLowerCase(),
      password: passwordField.value.value,
      phone: phoneNumberField.value.value,
      isMailing: isMailingField.value.value,
    });
  }

  loadingSignUp.deactivate();
}

function setFormTouched(): void {
  form.setTouched({
    [ELoginFormFields.Phone]: true,
    [ELoginFormFields.Email]: true,
    [ELoginFormFields.Password]: true,
    [ELoginFormFields.ConfirmPassword]: true,
  });
}

async function validateEmail(): Promise<void> {
  try {
    if (!emailField.value.value) {
      return;
    }
    const { status: isTaken } = await AccountApiService.validateEmail(emailField.value.value.toLocaleLowerCase());
    if (isTaken) {
      emailField.setErrors(EValidatorsMessages.EmailTaken);
    }
  } catch (error) {
    console.error(error);
  }
}

function setConfirmPasswordValidation(): void {
  validationSchema[ELoginFormFields.ConfirmPassword] = [
    Validators.required(EValidatorsMessages.Required),
    Validators.isEqual(passwordField.value, EValidatorsMessages.ConfirmPassword),
  ];
}

onBeforeMount(() => {
  setConfirmPasswordValidation();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-supplier-sign-up-personal-form {
  &__field {
    padding-bottom: 0;

    &--hidden {
      opacity: 0;
      height: 0;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-child(n + 2) {
      margin-top: 12px;

      :deep(.mm-input__input-container) {
        .mm-input__icon {
          top: 29px;
        }
      }

      :deep(.mm-password-field__container) {
        .mm-password-field__field {
          padding-bottom: 0;
        }
      }
    }
  }

  &__agreement {
    margin-top: 32px;
  }

  &__footer {
    margin-top: 32px;
  }

  &__button {
    width: 100%;
    padding: 18px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__account {
    margin-top: 24px;
    width: 100%;
    text-align: center;
    color: $supplier-sign-up-hint-c;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
